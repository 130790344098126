import React from "react";
import { Link } from "gatsby";
import SEO from "../components/SEO";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import BannerUX from "../components/BannerUX";

const LandingUX = (props) => {
  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": "https://brandinavian.com/#organization",
        name: "Brandinavian",
        url: "https://brandinavian.com/",
        sameAs: [
          "https://www.facebook.com/brandinavian/",
          "https://www.instagram.com/brandinavian/",
          "https://www.linkedin.com/company/brandinavian/",
          "https://twitter.com/brandinavian",
        ],
        logo: {
          "@type": "ImageObject",
          "@id": "https://brandinavian.com/#logo",
          inLanguage: "en-US",
          url:
          "https://cdn.brandinavian.com/wp-content/uploads/2020/09/brandinavian-logo.png",
          width: 1112,
          height: 116,
          caption: "Brandinavian | Modern Web Development",
        },
        image: { "@id": "https://brandinavian.com/#logo" },
      },
      {
        "@type": "WebSite",
        "@id": "https://brandinavian.com/#website",
        url: "https://brandinavian.com/",
        name: "Brandinavian",
        description: "USA Web Developer",
        publisher: { "@id": "https://brandinavian.com/#organization" },
        inLanguage: "en-US",
      },
      {
        "@type": "WebPage",
        "@id": "https://brandinavian.com/ux-engineering/#webpage",
        url: "https://brandinavian.com/ux-engineering/",
        name: "UX Engineering | Brandinavian",
        isPartOf: { "@id": "https://brandinavian.com/#website" },
        description:
          "Transform your design language into reusable component libraries. Get started with Brandinavian today!",
        breadcrumb: {
          "@id": "https://brandinavian.com/ux-engineering/#breadcrumb",
        },
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://brandinavian.com/ux-engineering"],
          },
        ],
      },
      {
        "@type": "BreadcrumbList",
        "@id": "https://brandinavian.com/ux-engineering/#breadcrumb",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@type": "WebPage",
              "@id": "https://brandinavian.com/",
              url: "https://brandinavian.com/",
              name: "Web developer",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@type": "WebPage",
              "@id": "https://brandinavian.com/ux-engineering",
              url: "https://brandinavian.com/ux-engineering",
              name: "Modern web development",
            },
          },
        ],
      },
    ],
  };

  return (
    <Layout altLogo={"black"}>
      <SEO
       title="UX Engineering | Brandinavian"
       lang="en-US"
       schemaMarkup={schema}
       description="Transform your design language into reusable component libraries."
     />
      <BannerUX />
      <div id="main" className="alt darkmode">
        <section className="pre-article dark">
          <li className="tag">User Experience Engineering</li>
        </section>
        <section id="one" className="article-section main-service">
          <div className="inner article service-article">
            <header className="major">
              <h2>Let's get organized</h2>
            </header>
            <p>
            The discipline of <b>User Experience Engineering</b> pertains to where design and web development intersect. As you develop and grow your brand, having concrete styleguides and reusable sections of components to use on your website become paramount to productivity and maintaining the same look and feel. Especially as more and more people get involved in communicating on your behalf; Invent once - get more done, while maintaining aesthetic and <a
                     href="https://developers.google.com/web/fundamentals/accessibility/semantics-aria/aria-labels-and-relationships"
                     target="_blank"
                     rel="noopener"
                     rel="noreferrer"
                   >important semantic HTML</a> markup.
            </p>
            <div className="dark-mode-image blue">
              <Img fluid={props.data.imageOne.childImageSharp.fluid} alt="" />
              <li className="tag branded">BRANDINAVIAN</li>
            </div>
            <header className="major">
              <h2>Best practice all the way</h2>
            </header>
            <p>
            If you have ever worked with any large organization or business, you will probably know what I am talking about, when I say that tasks and assignments tend to pass through multiple hands before they are ever realized. The website management of such an organization or business is no exception: "<i><b>Who does what, and where did that external agency put this again?</b></i>". In such an environment, it can be hard to ensure that every little thing that is published on your website, lives up to the fairly high standards of <a
                     href="https://developers.google.com/web/fundamentals/accessibility/semantics-aria"
                     target="_blank"
                     rel="noopener"
                     rel="noreferrer"
                   >search engines and disabled users</a>. By having an arsenal of plug-and-play components that are coded to live up to your styleguide and to best practice HTML semantics, you can circumvent the potential pitfall of having the integrity of your brand compromised by oversight or mishaps.</p>
            <div className="dark-mode-image green">
              <Img fluid={props.data.imageTwo.childImageSharp.fluid} alt="" />
              <li className="tag branded">BRANDINAVIAN</li>
            </div>
                   <header className="major">
                    <h2>Think long-term</h2>
                  </header>
                   <p>
                   In my opinion, it should be in the interest of everyone who aspires for scalability to establish their own design system. A system like this contains all the building blocks that you need to construct your communication. In the design language, you have the visual design assets and branding guidelines like the color palette, typographies, sizings, usage of logos, and accessibility guidelines. In the component library, you have the coded components that are built using <b>best-practice</b> HTML, CSS, and JavaScript. In the style guide, you have the actual website that encapsulates the component library and design language. This is what I can help you create.
                   </p>

           <div className="dark-mode-image yellow">
              <Img fluid={props.data.imageThree.childImageSharp.fluid} alt="" />
              <li className="tag branded">BRANDINAVIAN</li>
            </div>
            <header className="major">
              <h2>Inventing from scratch</h2>
            </header>
            <p>I can help you all the way. My interdisciplinary skillset allows me to help you from conceptual sketching, to creating design files - to actually coding and implementing your component library. If you are just getting started on having to launch a new website, this is a really good opportunity to establish a <b>solid framework</b> for yourself to work within. If you already have a large website, we can simply work toward progessively developing reusability within the ecosystem that you have established already.</p>
            <ul className="actions align-center">
              <li>
                <Link to="/contact" className="button">
                  Get organized today!
                </Link>
              </li>
            </ul>
          </div>
          <div className="inner article about-author">
            <div className="author-image-box">
              <Img
                className="large-author-image"
                fluid={props.data.imageFour.childImageSharp.fluid}
              />
            </div>
            <div className="author-information">
              <header className="major special">
                <h2 className="large-author-name">Hi, I'm Mads</h2>
              </header>
              <p className="author-biography">
                I'm a Full Stack Engineer and Media Science graduate. Through
                Brandinavian, I help awesome people all over the world,
                designing and engineering digital solutions. I build
                best-in-class, scalable web applications, that convert and
                communicate well.
              </p>
              <p className="author-links" />
              <li className="tag">
                <Link to="/contact">Get in touch</Link>
              </li>
              <li className="tag">
                <Link to="/contact">Request Resume</Link>
              </li>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default LandingUX;

export const uxEngQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "cube.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo: file(relativePath: { eq: "longterm.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageThree: file(relativePath: { eq: "invention.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageFour: file(relativePath: { eq: "creative-author.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

